html {
  background-color: #191c1d;
  /* Using background color from Flutter palette */
  height: 100vh;
}

body,
.bottomBar {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  font-family: 'Cabin', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e1e3e3;
  /* Using onSurface color from Flutter palette */
}

.version-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 5px;
  display: flex;
  color: #a1a1a1;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
}

#root {
  display: flex;
  justify-content: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.centered {
  justify-content: center;
  align-items: center;
}

.logo-image {
  height: 50px;
}

.error {
  color: #93000a;
}

button,
input {
  background-color: #025795;
  /* Using primaryContainer color from Flutter palette */
  color: #e1e3e3;
  /* Using onPrimaryContainer color from Flutter palette */
  border: none;
  border-radius: 4px;
  padding: 20px 30px;
  border-radius: 12px;
  font-family: 'Cabin', sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #22666e;
  /* Using secondaryContainer color from Flutter palette */
}

input:disabled {
  background-color: #40444b;
  color: #a1efff;
  /* Using secondaryContainer color from Flutter palette */
}

.vertical-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-container {
  color: #ffffff;
  /* Using onSurface color for contrast */
  padding: 20px;
  font-family: Arial, sans-serif;
  padding-bottom: 80px;
}

.file-input {
  margin-bottom: 20px;
  color: #ffffff;
  /* Using onSurface color for contrast */
  background-color: #40444b;
  /* Using surfaceVariant color from Flutter palette */
  border: none;
  padding: 10px;
}

.file-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px;
}

.file-item {
  background-color: #292c2d;
  /* Using surfaceVariant color from Flutter palette */
  border-radius: 10px;
  padding: 10px 20px;
  height: 50px;
}

.progress-container {
  display: flex;
  align-items: center;
}

.progress-bar {
  width: 100%;
  margin-right: 10px;
}


.done-button {
  margin-top: 20px;
  padding: 10px 20px;
}

.file-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-container {
  position: relative;
  display: flex;
  align-items: center;
}



.checkmark {
  stroke-dasharray: 38;
  stroke-dashoffset: 38;
  animation: drawCheckmark 0.3s ease forwards;
  transform-origin: center;
}

@keyframes drawCheckmark {
  to {
    stroke-dashoffset: 0;
  }
}

.bottomBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #191c1dd0;
  /* Using background color from Flutter palette */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-desc {

  /* Using error color from Flutter palette */
  font-size: 14px;
  font-style: italic;
}

.upload-stats {
  margin-bottom: 20px;
}

.upload-stats table {
  width: 100%;
  border-collapse: collapse;
}

.upload-stats td {
  padding: 10px;
  text-align: center;
  border-radius: 6px;
}

.upload-stats tr:first-child td {
  background-color: #006876;
  font-weight: bold;
}

.album-details {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #292c2d;
  border-radius: 4px;
  text-align: center;
}

.album-details h2 {
  margin: 0 0 10px 0;
}

.album-details p {
  margin: 5px 0;
}